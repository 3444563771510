import React, { useEffect, useRef, useState } from "react";
import CustomModal from "#components/common/Modal";
import ModalContent from "#components/common/Modal/ModalContent";
import ModalHeader from "#components/common/Modal/ModalHeader";
import { BaseModalProps, ModalCloseReason } from "#customTypes/modals";
import AIChatInput from "#components/common/AIChat/components/AIChatInput";
import ModalFooter from "#components/common/Modal/ModalFooter";
import { Box, IconButton, styled } from "@mui/material";
import AIChatContent from "#components/common/AIChat/components/AIChatContent";
import { useMediaQueryProvider } from "#shared/context/MediaQueryProvider";
import { useAIChatProvider } from "#components/common/AIChat/context/AIChatProvider";
import useTranslation from "#utils/useTranslation";
import IconRevert from "#assets/svgs/revert.svg?react";
import Tooltip from "#shared/components/ui/Tooltip";
import colors from "#shared/config/theme/colors";

interface Props extends BaseModalProps {
  initialPrompt?: string;
}

export default function AIChatModal({ initialPrompt, isOpen, onClose }: Props) {
  const { t } = useTranslation();
  const { isDesktop } = useMediaQueryProvider();
  const { history, loading, setPrompt, stopGenerating, reset } = useAIChatProvider();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const [renderCount, setRenderCount] = useState(0);

  useEffect(() => {
    if (initialPrompt) {
      setPrompt(initialPrompt);
    }
  }, [initialPrompt]);

  useEffect(() => {
    if (!wrapperRef.current || !isOpen || !history.length) {
      return;
    }

    wrapperRef.current.scrollTo({
      top: wrapperRef.current.scrollHeight,
      behavior: "smooth",
    });
  }, [history, isOpen, renderCount]);

  const handleClose = () => {
    stopGenerating();
    onClose(ModalCloseReason.Dismiss);
  };

  return (
    <CustomModal
      onClose={handleClose}
      open={isOpen}
      disableDismiss={isDesktop}
      Header={
        <ModalHeader
          title={t("ui.ai_chat.title")}
          Actions={
            <>
              {history.length > 0 && (
                <Tooltip title={t("ui.ai_chat.tooltips.new_chat")}>
                  <IconButton color="secondary" onClick={reset}>
                    <IconRevert />
                  </IconButton>
                </Tooltip>
              )}
            </>
          }
          onClose={handleClose}
        />
      }
      Content={
        <StyledModalContent
          ref={wrapperRef}
          onLoad={() => setRenderCount((prev) => prev + 1)}
        >
          <ChatContentWrapper>
            <AIChatContent
              loading={loading}
              messages={history}
              onSelectTemplate={setPrompt}
              onClose={handleClose}
            />
          </ChatContentWrapper>
        </StyledModalContent>
      }
      Footer={
        <ModalFooter sx={{ background: colors.base.transparent }}>
          <AIChatInput />
        </ModalFooter>
      }
    />
  );
}

const StyledModalContent = styled(ModalContent)`
  box-sizing: border-box;
  max-height: calc(100dvh - 190px);

  ${({ theme }) => theme.breakpoints.down("sm")} {
    max-height: calc(100dvh - 161px);
  }
`;

const ChatContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  min-height: min(600px, 80dvh - 185px);
`;
