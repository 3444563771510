const colors = {
  base: {
    white: "#FFFFFF",
    black: "#000000",
    transparent: "transparent",
    surface1: "#FAFAFA",
    surface2: "#F7F8FC",
    outline1: "#D0D2DB",
    outline2: "#63656D",
  },
  grey: {
    20: "#F7F8FC",
    50: "#F5F7FF",
    100: "#ECEFF8",
    200: "#E1E4ED",
    300: "#D0D2DB",
    400: "#ABADB6",
    500: "#8B8D95",
    600: "#63656D",
    700: "#50525A",
    800: "#50525A",
    900: "#50525A",
    default: "#8B8D95",
  },
  neutral: {
    50: "#FBFBFB",
  },
  brand: {
    100: "#BBDEFF",
    200: "#8ECAFF",
    300: "#5EB5FF",
    400: "#37A4FF",
    500: "#0F94FF",
    600: "#1C86FD",
    700: "#126AE8",
    800: "#126AE8",
    900: "#126AE8",
    default: "#126AE8",
  },
  success: {
    100: "#F0FDF4",
    200: "#DCFCE7",
    300: "#BBF7D0",
    400: "#4ADE80",
    500: "#22C559",
    600: "#16A34A",
    700: "#14532D",
    800: "#14532D",
    900: "#14532D",
    default: "#16A34A",
  },
  error: {
    50: "#FFE9EC",
    100: "#FFC7CC",
    200: "#F48F8F",
    300: "#E86365",
    400: "#EF3D3F",
    500: "#F02622",
    600: "#E21622",
    700: "#D0011D",
    800: "#D0011D",
    900: "#D0011D",
    default: "#EF3D3F",
  },
  warning: {
    100: "#FFFBEB",
    200: "#FEF3C7",
    300: "#FDE68A",
    400: "#FCD34D",
    500: "#F59E0B",
    600: "#D97706",
    700: "#92400E",
    800: "#92400E",
    900: "#92400E",
    default: "#F59E0B",
  },
  text: {
    primaryDark: "#13141B",
    secondaryDark: "#50525A",
    tertiaryDark: "#8B8D95",
    disabledDark: "#D0D2DB",
    primaryLight: "#FFFFFF",
    secondaryLight: "#ECEFF8",
    tertiaryLight: "#E1E4ED",
    disabledLight: "#D0D2DB",
  },
  input: {
    background: "#FFFFFF",
    border: "#D0D2DB",
    backgroundFocused: "#FFFFFF",
    placeholder: "#8B8D95",
    disabled: "#ABADB6",
  },
  gradient: {
    ai: {
      start: "#176AE9",
      end: "#925FFF",
      value: "linear-gradient(315deg, #925FFF 0%, #176AE9 100%)",
    },
  },
};

export default colors;
