import useMyOwnedChannels from "#components/pages/Feed/ChannelsSidebar/useMyOwnedChannels";
import { identifyPathname } from "#utils/routeHelpers";
import { useLocation } from "react-router";

export default function useMagicButtonVisibility() {
  const { pathname } = useLocation();
  const { approvedChannels } = useMyOwnedChannels();

  const isSurveyPreview = identifyPathname.isSurveyPreview(pathname);
  const isBriefBuilder = identifyPathname.isBriefBuilder(pathname);
  const isBriefDetails = identifyPathname.isBriefDetails(pathname);

  const visible =
    !isBriefBuilder && !isSurveyPreview && !isBriefDetails && approvedChannels.length > 0;

  return {
    visible,
  };
}
