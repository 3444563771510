/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import useMyOwnedChannels from "#components/pages/Feed/ChannelsSidebar/useMyOwnedChannels";
import AppLoader from "#containers/Global/AppLoader";
import useQueryLoading from "#containers/Global/useQueryLoading";
import { ChannelListItem } from "#customTypes/channel";
import { MyOrganisationPosition } from "#customTypes/types";
import { useFetchMyOrganisationPositionsQuery } from "#features/organisationPositions/organisationPositionsAPI";
import { getOrganisationDomain, useGetWlaOrganisation } from "#utils/organisation";
import { Organisation } from "#customTypes/db";

interface ContextProps {
  positions: MyOrganisationPosition[];
  ownedChannels: ChannelListItem[];
  wlaOrganisation?: Organisation;
  isAppLoading: boolean;
  setAppLoading: () => void;
}

const GlobalContext = createContext<ContextProps>({
  positions: [],
  ownedChannels: [],
  isAppLoading: true,
  setAppLoading: () => {},
});

export function useGlobalAppProvider(): ContextProps {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("useGlobalAppProvider must be used within a GlobalAppProvider");
  }
  return context;
}

interface ProviderProps {
  children: ReactNode;
}

export default function GlobalAppProvider({ children }: ProviderProps) {
  const [isAppLoading, setIsAppLoading] = useState(true);

  const organisationPositionsQuery = useFetchMyOrganisationPositionsQuery({});
  const ownedChannelsQuery = useMyOwnedChannels();

  const domainOrganisation = getOrganisationDomain(window.location.href);
  const { organisation, isInitialized } = useGetWlaOrganisation(domainOrganisation);

  const isLoading = useQueryLoading([organisationPositionsQuery, ownedChannelsQuery]);

  useEffect(() => {
    setIsAppLoading(isLoading || !isInitialized);
  }, [isLoading, isInitialized]);

  const setAppLoading = useCallback(() => setIsAppLoading(true), []);

  const content = () => {
    if (isAppLoading) {
      return <AppLoader />;
    }

    return children;
  };

  const providerValue = useMemo(
    () => ({
      positions: organisationPositionsQuery.data ?? [],
      ownedChannels: ownedChannelsQuery.data ?? [],
      wlaOrganisation: organisation,
      isAppLoading,
      setAppLoading,
    }),
    [organisationPositionsQuery.data, ownedChannelsQuery.data, organisation, isAppLoading]
  );

  return (
    <GlobalContext.Provider value={providerValue}>{content()}</GlobalContext.Provider>
  );
}
