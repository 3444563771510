import { TranslationKey } from "#customTypes/translation";
import { removeToast, showToast } from "#features/toast/toastSlice";
import { ToastStateOptions, ToastType } from "#shared/types/toast";
import useTranslation from "#utils/useTranslation";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";

export default function useToast() {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const [lastToastId, setLastToastId] = useState<number | null>(null);

  const open = useCallback(
    (options: Omit<ToastStateOptions, "id">) => {
      const id = Date.now();
      setLastToastId(id);
      dispatch(showToast({ id, ...options }));
    },
    [dispatch]
  );

  const success = useCallback(
    (options: Omit<ToastStateOptions, "id" | "type">) => {
      open({
        ...options,
        type: "success",
        message: options.message || "",
      });
    },
    [open]
  );

  const warn = useCallback(
    (options: Omit<ToastStateOptions, "id" | "type">) => {
      open({
        ...options,
        type: "warning",
        message: options.message || "",
      });
    },
    [open]
  );

  const error = useCallback(
    (options?: Omit<ToastStateOptions, "id" | "type">) => {
      open({
        ...options,
        type: "error",
        message: options?.message || t("errors.something_went_wrong") || "",
      });
    },
    [open]
  );

  const close = useCallback(() => {
    if (!lastToastId) {
      return;
    }
    dispatch(removeToast(lastToastId));
  }, [dispatch, lastToastId]);

  return {
    open,
    success,
    error,
    warn,
    close,
  };
}

export const getDefaultToastMessage = (type: ToastType): TranslationKey | null => {
  switch (type) {
    case "error":
      return "errors.something_went_wrong";
    default:
      return null;
  }
};
