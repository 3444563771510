import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

export default function useIntersectObserver<T>({
  root = null,
  rootMargin = "0px",
  threshold = 0,
}): [Dispatch<SetStateAction<T | undefined>>, IntersectionObserverEntry | undefined] {
  const [entry, updateEntry] = useState<IntersectionObserverEntry | undefined>();
  const [nodeRef, setNodeRef] = useState<T>();
  const observer = useRef(
    typeof window !== "undefined"
      ? new IntersectionObserver(([entry]) => updateEntry(entry), {
          root,
          rootMargin,
          threshold,
        })
      : null
  );

  useEffect(() => {
    if (observer) {
      const { current: currentObserver } = observer;
      if (currentObserver) {
        currentObserver.disconnect();
        const refElement = nodeRef as HTMLElement;
        if (nodeRef) currentObserver.observe(refElement);

        return () => currentObserver.disconnect();
      }
    }
  }, [nodeRef]);

  return [setNodeRef, entry];
}

// this is a more versatile implementation than the one above, try using this one instead
interface Args {
  onEntryStateChange(entry: IntersectionObserverEntry): void;
  checkTicker?: boolean; // re-runs the intersection observer
  useViewportObserverRoot?: boolean;
  observedItems?: HTMLElement[]; // for supporting multiple obserbable items
}

export const useCustomIntersectionObserver = (args: Args) => {
  const wrapper = useRef<HTMLDivElement>(null);
  const observedItem = useRef<HTMLElement>(null);

  const observerCallback: IntersectionObserverCallback = (entries) => {
    entries.forEach((entry) => {
      args.onEntryStateChange(entry);
    });
  };

  useEffect(() => {
    const options: IntersectionObserverInit = {
      root: args.useViewportObserverRoot ? undefined : wrapper.current,
      rootMargin: "50px",
      threshold: 1,
    };

    const observer = new IntersectionObserver(observerCallback, options);

    args.observedItems?.forEach((item) => observer.observe(item));
    if (observedItem.current) {
      observer.observe(observedItem.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [args.checkTicker, args.observedItems]);

  return { wrapper, observedItem };
};
